import React from 'react';

import MessageGroup from './MessageGroup';
import createMessageGroups from './createMessageGroups';

const MessageGroups = ({ messages = [], platformId, disabled }) => {
  const groups = createMessageGroups(messages);

  return groups.map((group) => (
    <div key={group.messages[0]?.id} data-testid="MessageGroup">
      <MessageGroup
        disabled={disabled}
        messages={group.messages}
        platformId={platformId}
        speaker={group.speaker}
        timestamp={group.timestamp}
      />
    </div>
  ));
};

export default MessageGroups;
