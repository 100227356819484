import React, { useState } from 'react';
import clsx from 'clsx';

import Modal from './Modal';

import { SendIcon } from '../icons';

import style from './feedbackmodal.module.scss';
import { getBrand } from '../../utils/platforms';
import { questionsConfig } from './feedbackModalQuestionConfig';

const FeedbackModal = ({ disabled, saveFeedback }) => {
  const brand = getBrand();

  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [textAnswer, setTextAnswer] = useState('');
  const [npsAnswer, setNpsAnswer] = useState(null);

  const brandQuestionConfig = questionsConfig[brand];

  const { question, description, alternatives, isNpsQuestion, isBranch, verticalLayout } = brandQuestionConfig[
    questionIndex
  ];

  function handleAnswer(answeredQuestion, answer) {
    const updatedAnswers = [...answers, { question: answeredQuestion, answer }];
    setAnswers(updatedAnswers);

    if (isNpsQuestion) {
      setNpsAnswer(answer);
    }

    const doneLeavingFeedback = isBranch ? npsAnswer >= 7 : questionIndex === brandQuestionConfig.length - 1;

    saveFeedback({ answers: updatedAnswers, doneLeavingFeedback });
    if (!doneLeavingFeedback) {
      setQuestionIndex(questionIndex + 1);
    }
  }

  return (
    <Modal>
      <h3 className={style.heading}>{question}</h3>

      {description && <p className={style.description}>{description}</p>}

      {/* Single choice from a fixed set of answers */}
      {Array.isArray(alternatives) && (
        <div className={clsx(style['single-choice-answer'], verticalLayout && style['single-choice-answer__vertical'])}>
          {alternatives.map((alternative) => (
            <button
              key={alternative}
              className={style['single-choice-answer__button']}
              type="button"
              onClick={() => handleAnswer(question, alternative)}
              disabled={disabled}
            >
              {alternative}
            </button>
          ))}
        </div>
      )}

      {/* Freeform text answer */}
      {alternatives === 'textarea' && (
        <>
          <form
            className={style['text-answer']}
            onSubmit={(event) => {
              event.preventDefault();
              handleAnswer(question, textAnswer);
              setTextAnswer('');
            }}
          >
            <textarea
              className={style['text-answer__textarea']}
              name="answer"
              value={textAnswer}
              onChange={(event) => setTextAnswer(event.target.value)}
              maxLength={255}
              rows={3}
              aria-label="Kommentar"
              placeholder="Skriv gärna något..."
            />
            <button className={style['text-answer__submit']} type="submit">
              <SendIcon aria-label="Skicka" />
            </button>
          </form>
        </>
      )}
    </Modal>
  );
};

export default FeedbackModal;
