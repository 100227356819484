export const sendConversationStartedToParentWindow = () => window.parent.postMessage('chat_conversation_started', '*');
export const sendAgentRequestedToParentWindow = (data) =>
  window.parent.postMessage({ event: 'chat_agent_requested', ...data }, '*');
export const sendAgentAssignedToParentWindow = (data) =>
  window.parent.postMessage({ event: 'chat_agent_assigned', ...data }, '*');
export const sendExternalLinkClicked = (data) =>
  window.parent.postMessage({ event: 'chat_external_link_clicked', ...data }, '*');
export const sendMinimizeToParentWindow = () => window.parent.postMessage('chat_minimized', '*');
export const sendCloseToParentWindow = () => window.parent.postMessage('chat_closed', '*');
export const sendCloseConfirmedToParentWindow = () => window.parent.postMessage('chat_close_confirmed', '*');
