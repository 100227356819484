export const BOT_AGENT = {
  agentId: 'bot',
  name: 'Botterino',
};

export const TELE2_BOT_AGENT = {
  agentId: 'bot',
  name: 'Tele2 boten',
};

export const COMVIQ_BOT_AGENT = {
  agentId: 'bot',
  name: 'Compis',
};

export const COMVIQ_KINDLY_BOT_AGENT = {
  agentId: 'bot',
  name: 'Kindly Chatbot',
};

export const COMHEMPLAY_BOT_AGENT = {
  agentId: 'bot',
  name: 'Tele2 Play chat',
};

export const CAMPAIGN_AGENT = {
  agentId: 'campaign',
  name: 'Kampanj',
};

export const SYSTEM_AGENT = {
  agentId: 'system',
  name: 'Info',
};

export const hasHumanAgentName = (name) =>
  [
    BOT_AGENT.name,
    COMHEMPLAY_BOT_AGENT.name,
    COMVIQ_BOT_AGENT.name,
    COMVIQ_KINDLY_BOT_AGENT.name,
    CAMPAIGN_AGENT.name,
    SYSTEM_AGENT.name,
    TELE2_BOT_AGENT.name,
  ].includes(name) === false;
