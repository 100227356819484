import React, { useState } from 'react';
import axios from 'axios';

import Send from './Send';
import Input from './Input';
import FileUpload from './FileUpload';
import ImagePreview from './ImagePreview';
import { notifyTyping, cancelTyping } from './notifyTyping';

import style from './inputform.module.scss';

const InputIcon = ({ text, handleImageSelected, sending, imageURL, disabled, conversationHasNoMessages }) => {
  if (disabled) {
    return null;
  }

  if (text || imageURL) {
    return <Send disabled={sending} />;
  }

  return !conversationHasNoMessages ? <FileUpload onImageSelected={handleImageSelected} /> : null;
};

const InputForm = ({ userId, platformId, onSubmit, disabled, conversationHasNoMessages, state }) => {
  const [sending, setSending] = useState(false);

  const [imageURL, setImageURL] = useState(undefined);
  const [text, setText] = useState('');

  const reset = () => {
    setText('');
    setImageURL(undefined);
  };

  const handleImageSelected = (value) => {
    setImageURL(value);
    setText('');
  };

  const handleChange = async (event) => {
    setText(event.target.value);
    notifyTyping(userId, platformId, event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    cancelTyping();

    if (text.trim() === '' && !imageURL) {
      return;
    }

    if (text.trim() === 'new session') {
      onSubmit(true);
      reset();
      return;
    }

    try {
      setSending(true);

      await axios.post(`/api/messages/${userId}`, {
        platformId,
        image: imageURL,
        text,
      });
    } catch (error) {
      console.log('Error when sending message:', error);
    } finally {
      setSending(false);
    }

    onSubmit();
    reset();
  };

  const inputDisabled = sending || disabled || conversationHasNoMessages;

  return (
    <form onSubmit={handleSubmit} className={style.container}>
      <div className={style.inputContainer}>
        {imageURL && <ImagePreview imageURL={imageURL} onCancelClicked={() => handleImageSelected(undefined)} />}
        {!imageURL && (
          <Input
            state={state}
            onChange={handleChange}
            text={text}
            disabled={inputDisabled}
            conversationHasNoMessages={conversationHasNoMessages}
            onSubmit={handleSubmit}
          />
        )}
      </div>

      <InputIcon
        imageURL={imageURL}
        text={text}
        handleImageSelected={handleImageSelected}
        sending={sending}
        platformId={platformId}
        disabled={disabled}
        conversationHasNoMessages={conversationHasNoMessages}
      />
    </form>
  );
};

export default InputForm;
