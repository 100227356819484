/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import style from './style.module.scss';
import { Pill } from './Pill';

export const PillContainer = ({ disabled, message }) => {
  const [isDisabled, setDisabled] = React.useState(false);

  const setContainerDisabled = () => {
    setDisabled(true);
  };

  useEffect(() => {
    setDisabled(disabled);
  }, [disabled]);

  const quickReplyCount = message.quickReplies?.length || 0;
  const hasPresentationInline = message.quickReplies?.some((qr) => qr.presentation === 'inline') || false;
  const presentation = hasPresentationInline ? 'inline' : undefined;

  const classNames = clsx(isDisabled ? style.pillshidden : style.pills);

  return (
    <div data-testid="Pills" type="button" className={classNames}>
      {message.quickReplies.map(({ type, variant, target, text, url, windowOpen, payload }, index) => {
        return (
          <Pill
            key={index}
            count={quickReplyCount}
            presentation={presentation}
            disabled={isDisabled}
            type={type}
            variant={variant}
            target={target}
            payload={payload}
            url={url}
            windowOpen={windowOpen}
            text={text}
            setDisable={setContainerDisabled}
          />
        );
      })}
    </div>
  );
};
