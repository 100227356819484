import React from 'react';

import { AvatarWithName } from '../Avatar';
import { sendMinimizeToParentWindow } from '../../utils/postMessageToParent';
import { CloseIcon, MinimizeIcon } from '../icons';

import style from './header.module.scss';

const Header = ({ onCloseClick = () => {}, currentAgent, platformId }) => {
  const handleCloseClick = () => onCloseClick();

  return (
    <div className={style.header}>
      <AvatarWithName agent={currentAgent} />
      {platformId !== 'helpdesk-siebel' && (
        <div className={style.buttons}>
          <button type="button" onClick={sendMinimizeToParentWindow} data-testid="minimize-button">
            <MinimizeIcon />
          </button>
          <button type="button" onClick={handleCloseClick} data-testid="Side_closeButton">
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
