import { sendConversationStartedToParentWindow } from '../utils/postMessageToParent';

let messageSent = false;

export const triggerConversationStarted = () => {
  if (messageSent) {
    return;
  }

  sendConversationStartedToParentWindow();

  messageSent = true;
};
