/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './fonts.module.scss';

import App from './components/App';

import { getPlatformId } from './utils/platforms';

ReactDOM.render(<App platformId={getPlatformId()} />, document.getElementById('root'));
