import React, { useRef } from 'react';
import Compressor from 'compressorjs/dist/compressor.esm';

import style from './fileupload.module.scss';
import { AttachmentIcon } from '../icons';

const convertToBase64 = (file, callback) => {
  const reader = new FileReader();

  reader.onload = () => callback(null, reader.result);
  reader.onerror = (error) => callback(error);

  reader.readAsDataURL(file);
};

const compressFile = (file, cb) => {
  // eslint-disable-next-line no-new
  new Compressor(file, {
    quality: 0.8,
    maxWidth: 800,
    maxHeight: 800,
    convertSize: 10000,
    success: cb,
  });
};

export default ({ onImageSelected }) => {
  const fileInput = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    e.target.value = '';

    if (file) {
      if (/^image\//i.test(file.type)) {
        compressFile(file, (result) => {
          convertToBase64(result, (err, dataURL) => {
            if (err) {
              return;
            }
            onImageSelected(dataURL);
          });
        });
      } else {
        alert('Du kan bara bifoga bilder.');
      }
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        data-testid="FileUpload_input"
        ref={fileInput}
      />
      <button type="button" className={style.attachmentBtn} title="Skicka bild" onClick={handleClick}>
        <AttachmentIcon />
      </button>
    </>
  );
};
