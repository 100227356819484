import React from 'react';

import { isBoxerPlatform, isComhemplayPlatform, isComviqPlatform } from '../../utils/platforms';

import style from './integritylink.module.scss';

const IntegrityLink = ({ href, brandName }) => {
  const linkText = `Läs om hur ${brandName} behandlar personuppgifter`;

  return (
    <a className={style.integrity} target="_blank" rel="noopener noreferrer" href={href}>
      {linkText}
    </a>
  );
};

export default ({ platformId }) => {
  if (isBoxerPlatform(platformId)) {
    return <IntegrityLink brandName="Boxer" href="https://www.boxer.se/om-boxer/integritet" />;
  }

  if (isComviqPlatform(platformId)) {
    return <IntegrityLink brandName="Comviq" href="https://www.comviq.se/hjalp/villkor-och-avtal/integritet" />;
  }

  if (isComhemplayPlatform(platformId)) {
    return <IntegrityLink brandName="Tele2 Play" href="https://www.tele2.se/integritet" />;
  }

  return (
    <IntegrityLink
      brandName="Tele2"
      href="https://www.tele2.se/kundservice/information/integritetspolicy-och-cookies"
    />
  );
};
