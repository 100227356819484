import React from 'react';
import { Image } from './Image';
import { getClassNamesForMessage, getTestId } from './messageUtils';

export const getImageInfo = (message) => {
  if (message.imageId) {
    return { src: `/images/${message.imageId}` };
  }

  if (message.imageUrl) {
    return { src: message.imageUrl, alt: message.altText };
  }

  return null;
};

const MessageTypeImage = ({ message }) => {
  const imageInfo = getImageInfo(message);

  if (!imageInfo) {
    return null;
  }

  return (
    <div className={getClassNamesForMessage(message)}>
      <Image
        src={imageInfo.src}
        href={imageInfo.src}
        altText={imageInfo.alt}
        testId={getTestId('Message_imageFrom', message)}
      />
    </div>
  );
};

export default MessageTypeImage;
