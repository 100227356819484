import React from 'react';
import { getBrand } from '../../utils/platforms';

import style from './modal.module.scss';

export default ({ children }) => {
  const brand = getBrand();

  return (
    <div className={style['modal-scrollable']}>
      <div className={style[`modal-backdrop`]} />
      <div className={style['modal-main']}>
        <div className={style[`modal-body-${brand}`]}>{children}</div>
      </div>
    </div>
  );
};
