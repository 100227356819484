export default () => {
  const params = new URLSearchParams(window.location.search);

  const platformId = params.get('platformId');
  const customerId = params.get('customerId');
  const niSessionId = params.get('niSessionId');
  const niSalesLeadId = params.get('niSalesLeadId');
  const niFeedbackId = params.get('niFeedbackId');
  const gid = params.get('gid');
  const ga = params.get('ga');
  const crmSessionId = params.get('crmSessionId');
  const intentProductFamily = params.get('intentProductFamily');
  const intent = params.get('intent');
  const slug = params.get('slug');

  return {
    platformId,
    customerId,
    niSessionId,
    niSalesLeadId,
    niFeedbackId,
    gid,
    ga,
    crmSessionId,
    intentProductFamily,
    intent,
    slug,
  };
};
