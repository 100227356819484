import React from 'react';
import classnames from 'classnames';
import { getUserId } from '../../utils/userId';
import { logger } from '../../utils/LoggerClient';

import style from './style.module.scss';
import { triggerExternalLinkClicked } from '../triggerExternalLinkClicked';

export const logOnLinkClick = ({ href, type = 'inline-link', target = '_blank', label = '' }) => {
  const userId = getUserId();

  const triggerEvent = {
    userId,
    href,
    type,
    target,
    label,
  };
  triggerExternalLinkClicked(triggerEvent);

  logger.info(`Webchat ${type} clicked: "${href}", userId: ${userId}`);
};

export const getTestId = (prefix, message) => `${prefix}${message.agent ? 'Agent' : 'User'}`;

export const getClassNamesForMessage = (message) =>
  classnames(style.bubble, {
    [style.userBubble]: !message.agent,
    [style.markdown]: message.type === 'markdown',
  });

export const linkComponent = (href, children, key, classNames = undefined) => (
  <a
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    onClick={(e) => logOnLinkClick({ href: e.currentTarget.href })}
    key={key || new Date()}
    className={classNames}
  >
    {children}
  </a>
);
