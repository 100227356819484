import React from 'react';

import Modal from './Modal';
import { PrimaryButton, GhostButton } from '../Button';

import style from './closemodal.module.scss';

const CloseConfirmationModal = ({ confirm, cancel }) => (
  <Modal>
    <div>
      <h3>Är du säker på att du vill avsluta chatten?</h3>
      <p>Din aktuella chatt kommer att avslutas och konversationen försvinna. </p>
    </div>

    <PrimaryButton
      className={`${style.modalBtn} ${style.confirmBtn}`}
      type="button"
      onClick={confirm}
      data-testid="CloseModal_confirmButton"
    >
      Bekräfta
    </PrimaryButton>

    <GhostButton className={style.modalBtn} type="button" onClick={cancel} data-testid="CloseModal_cancelButton">
      <span>Ångra</span>
    </GhostButton>
  </Modal>
);

export default CloseConfirmationModal;
