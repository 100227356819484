export const isBankIdMessage = (message) => (!message ? false : message.type === 'bankid_authenticate');

// Note: check last 2 messages as we (right now) send "a double" :-)
const LAST_N_MESSAGES_TO_CHECK_FOR_BANKID = 2;
export const lastMessageIsBankID = (messages) =>
  messages.slice(-LAST_N_MESSAGES_TO_CHECK_FOR_BANKID).some((m) => isBankIdMessage(m));

export const lastMessageIsQuickReply = (messages) =>
  !messages ? false : !!messages[messages.length - 1]?.quickReplies;

export const lastMessageIsOptionalQuickReply = (messages) =>
  !messages ? false : !!messages[messages.length - 1]?.quickReplies?.find((q) => q.optional);

/**
 * @deprecated not used?
 */
export const containsQuickReplies = (messages) => messages.some((m) => m.quickReplies);

/**
 * Remove all QuickReplies and message type from messages except for
 * if QuickReply/message type is the current/last item in messages
 *
 * @param {Array} messages
 * @param {Array} typesToExclude
 * @returns
 */
export const filterMessagesByQuickRepliesAndMessageType = (messages, typesToExclude = ['bankid_authenticate']) => {
  if (!Array.isArray(messages)) {
    return [];
  }

  let p1 = [];
  let p2 = [];
  p1 = messages.slice(0, -1).map((m, idx) => {
    const { quickReplies, ...rest } = m;

    if (idx < messages.length - LAST_N_MESSAGES_TO_CHECK_FOR_BANKID && typesToExclude.includes(m.type)) {
      return null;
    }

    if (!lastMessageIsBankID(messages) && typesToExclude.includes(m.type)) {
      return null;
    }

    const qr = (quickReplies || []).map((q) => {
      return {
        ...q,
        variant: 'historical',
      };
    });

    return {
      ...rest,
      quickReplies: qr,
    };
  });
  p2 = messages.slice(-1);

  const filteredMessages = [...p1, ...p2].filter((o) => o);

  return filteredMessages;
};
