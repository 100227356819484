import axios from 'axios';

import getQueryObject from './getQueryObject';
import { clearUserId } from './userId';
import { sendCloseToParentWindow } from './postMessageToParent';
import { clearAgentRequested } from './agentRequested';

export const initializeConversation = async (userId, platformId) => {
  try {
    await axios.put(`/api/conversations/${userId}`, getQueryObject());
    return true;
  } catch (error) {
    clearUserId(platformId);
    clearAgentRequested(platformId);
    sendCloseToParentWindow();
    return false;
  }
};
