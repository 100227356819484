import axios from 'axios';
import throttle from 'lodash.throttle';
import { NOTIFY_TYPING_TIMEOUT } from './notifyTypingTimeout';

export const notifyTyping = throttle(
  async (userId, platformId, text) => {
    try {
      await axios.post(`/api/messages/${userId}/typing_on`, { text, platformId });
    } catch (error) {
      // ignore
    }
  },
  NOTIFY_TYPING_TIMEOUT,
  { leading: true, trailing: true },
);

export const cancelTyping = () => {
  notifyTyping.cancel();
};
