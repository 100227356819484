/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getBrand, isComviqBrand } from '../../utils/platforms';

import { ReactComponent as SendIconComhemplay } from '../../assets/svgs/comhemplay/avatar.svg';
import { ReactComponent as SendIconComviq } from '../../assets/svgs/comviq/compis.svg';

export default (props) => {
  const brand = getBrand();

  if (isComviqBrand(brand)) {
    return <SendIconComviq {...props} />;
  }

  return <SendIconComhemplay {...props} />;
};
