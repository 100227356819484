import React, { useState } from 'react';
import axios from 'axios';

import FeedbackModal from './FeedbackModal';
import CloseConfirmationModal from './CloseConfirmationModal';
import { sendCloseConfirmedToParentWindow, sendCloseToParentWindow } from '../../utils/postMessageToParent';
import { getAgentAssigned } from '../../utils/agentAssigned';

const sendClientLeftEvent = (userId, platformId) => {
  const text = 'Kunden har stängt chatten.';

  return axios.post(`/api/messages/${userId}/client_left`, { platformId, text });
};

const CloseModal = ({
  userId,
  platformId,
  showCloseModal,
  cancelCloseModal,
  clearSession,
  hasConversationMessages,
}) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [disableFeedbackModal, setDisableFeedbackModal] = useState(false);

  const handleCloseChat = () => {
    if (hasConversationMessages) {
      clearSession();
    }

    sendCloseToParentWindow();
  };

  const saveFeedback = async ({ answers, doneLeavingFeedback }) => {
    try {
      await axios.post('/api/feedback', { answers, userId });
    } catch (error) {
      console.error('Error when sending feedback:', error.message);
    } finally {
      if (doneLeavingFeedback) {
        setDisableFeedbackModal(true);
        handleCloseChat();
      }
    }
  };

  const confirmCloseModal = () => {
    const isAgentAssigned = getAgentAssigned();

    sendCloseConfirmedToParentWindow();

    if (hasConversationMessages) {
      sendClientLeftEvent(userId, platformId);
    }

    clearSession();

    if (isAgentAssigned === undefined || isAgentAssigned) {
      setShowFeedbackModal(true);
    } else {
      handleCloseChat();
    }
  };

  if (!showCloseModal) {
    return null;
  }

  if (showFeedbackModal) {
    return <FeedbackModal saveFeedback={saveFeedback} disabled={disableFeedbackModal} />;
  }

  return <CloseConfirmationModal confirm={confirmCloseModal} cancel={cancelCloseModal} />;
};

export default CloseModal;
