import { sendAgentAssignedToParentWindow } from '../utils/postMessageToParent';

let messageSent = false;

export const triggerAgentAssigned = (data) => {
  if (messageSent) {
    return;
  }

  sendAgentAssignedToParentWindow(data);

  messageSent = true;
};
