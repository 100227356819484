import React from 'react';

import InputForm from './input/InputForm';
import IntegrityLink from './messages/IntegrityLink';
import MessageList from './messages/MessageList';
import Header from './header/Header';
import { ChatEnded, PlatformClosed } from './messages/ChatEnded';
import { sendCloseToParentWindow } from '../utils/postMessageToParent';

import style from './side.module.scss';
import {
  filterMessagesByQuickRepliesAndMessageType,
  lastMessageIsBankID,
  lastMessageIsOptionalQuickReply,
  lastMessageIsQuickReply,
} from '../utils/messages';
import Maintenance from './Maintenance';

export const PlatformClosedView = ({ platformId }) => (
  <div className={style.side__container}>
    <Header platformId={platformId} onCloseClick={sendCloseToParentWindow} />

    <IntegrityLink platformId={platformId} />

    <PlatformClosed platformId={platformId} />
  </div>
);

export const NotSupportedView = ({ message, platformId }) => {
  return (
    <div className={style.side__container}>
      <Header platformId={platformId} onCloseClick={sendCloseToParentWindow} />

      <p>{message}</p>
    </div>
  );
};

const Side = ({ platformId, onCloseClick, userId, conversation, maintenance, onMessageSent }) => {
  const { messages, ended, endedReason, currentAgent } = conversation;

  const filteredMessages = filterMessagesByQuickRepliesAndMessageType(messages);

  const isBankId = lastMessageIsBankID(messages);
  const isQuickReply = lastMessageIsQuickReply(messages);
  const isOptionalQuickReply = lastMessageIsOptionalQuickReply(messages);
  const isDisabled = (isBankId || (isQuickReply && !isOptionalQuickReply)) ?? false;

  return (
    <div className={style.side__container}>
      <Header platformId={platformId} onCloseClick={onCloseClick} currentAgent={currentAgent} />

      <IntegrityLink platformId={platformId} />

      <MessageList
        className={ended ? style.conversation__ended : ''}
        userId={userId}
        platformId={platformId}
        messages={filteredMessages}
        disabled={ended}
      />

      <Maintenance maintenance={maintenance} />

      {ended && <ChatEnded reason={endedReason} platformId={platformId} />}
      {!ended && (
        <InputForm
          onSubmit={onMessageSent}
          userId={userId}
          platformId={platformId}
          conversationHasNoMessages={messages.length === 0 || false}
          disabled={isDisabled}
          state={{
            isBankId,
            isQuickReply,
            isOptionalQuickReply,
          }}
        />
      )}
    </div>
  );
};

export default Side;
