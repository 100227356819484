import { debounce } from 'debounce';

const notificationSound = require('../sounds/notification.mp3');

const MIN_TIME_BETWEEN_SOUNDS = 10 * 1000;
const audio = new Audio(notificationSound);

const playSound = debounce(
  async () => {
    try {
      await audio.play();
    } catch (e) {
      // ignore error
    }
  },
  MIN_TIME_BETWEEN_SOUNDS,
  true,
);

export const triggerAudioNotification = () => {
  if (document.hasFocus()) {
    return;
  }

  playSound();
};
