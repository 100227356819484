import { emit, isConnected } from './clientSocket';

const HEARTBEAT_INTERVAL = 5 * 1000;
let intervalID = null;

const sendHeartbeat = (userId, platformId) => () => {
  if (userId && platformId && isConnected()) {
    emit('web-client-heart-beat', { userId, platformId });
  }
};

export const startHeartbeat = (userId, platformId) => {
  if (intervalID) {
    clearInterval(intervalID);
  }

  sendHeartbeat();
  intervalID = setInterval(sendHeartbeat(userId, platformId), HEARTBEAT_INTERVAL);
};

export const stopHeartbeat = () => {
  if (intervalID) {
    clearInterval(intervalID);
  }
};
