import { BOT_AGENT, COMVIQ_KINDLY_BOT_AGENT, CAMPAIGN_AGENT, SYSTEM_AGENT } from '../../utils/agent';

const MESSAGE_GROUP_CUTOFF_MS = 2 * 1000 * 60;

const getSpeaker = (message) => {
  if (message.type === 'info') {
    return {
      type: 'system',
    };
  }

  if (!message.agent) {
    return {
      type: 'user',
    };
  }

  // Compare with name for bot since let bot end changes agentId to bot
  if (message.agent.name === BOT_AGENT.name) {
    return {
      type: 'bot',
      ...BOT_AGENT,
    };
  }

  if (message.agent.agentId === CAMPAIGN_AGENT.agentId) {
    return {
      type: 'campaign',
      ...CAMPAIGN_AGENT,
    };
  }

  if (message.agent.agentId === SYSTEM_AGENT.agentId) {
    return {
      type: 'system',
      ...SYSTEM_AGENT,
    };
  }

  // Special case as Kindly sends agent: { name: 'Kindly Bot' }
  if (message.agent.agentId === 'bot') {
    return {
      type: 'bot',
      ...COMVIQ_KINDLY_BOT_AGENT,
    };
  }

  return {
    type: 'agent',
    ...message.agent,
  };
};

const shouldSplitIntoGroups = (dateSent, previousDateSent) => {
  const msSincePreviousMessage = new Date(dateSent).valueOf() - new Date(previousDateSent).valueOf();

  return msSincePreviousMessage > MESSAGE_GROUP_CUTOFF_MS;
};

const isSameSpeaker = (first, other) => {
  if (!other) {
    return false;
  }

  if (first.type !== other.type) {
    return false;
  }

  if (first.agentId !== other.agentId) {
    return false;
  }

  return true;
};

const reduceMessageGroups = (acc, message) => {
  const speaker = getSpeaker(message);
  const lastSpeaker = acc[acc.length - 1]?.speaker;
  const lastTimestamp = acc[acc.length - 1]?.timestamp;

  if (isSameSpeaker(speaker, lastSpeaker) === false || shouldSplitIntoGroups(message.createdAt, lastTimestamp)) {
    acc.push({
      messages: [],
      speaker,
      timestamp: null,
    });
  }

  acc[acc.length - 1].messages.push(message);
  acc[acc.length - 1].timestamp = message.createdAt;

  return acc;
};

export default (messages = []) => messages.reduce(reduceMessageGroups, []);
