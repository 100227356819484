import React from 'react';
import { BankIdContainer } from './BankIdContainer';
import { isBankIdMessage } from '../../utils/messages';
import MessageTypeMedia from './MessageTypeMedia';
import MessageTypeImage from './MessageTypeImage';
import MessageTypeInfo from './MessageTypeInfo';
import MessageTypeText from './MessageTypeText';
import MessageTypeMarkdown from './MessageTypeMarkdown';

export const Message = ({ message, disabled }) => {
  if (isBankIdMessage(message)) {
    return <BankIdContainer message={message} disabled={disabled} />;
  }

  switch (message.type) {
    case 'info':
      return <MessageTypeInfo message={message} />;
    case 'image':
      return <MessageTypeImage message={message} />;
    case 'media':
      return <MessageTypeMedia message={message} />;
    case 'markdown':
      return <MessageTypeMarkdown message={message} disabled={disabled} />;
    default:
      return <MessageTypeText message={message} disabled={disabled} />;
  }
};
