import { brands } from '../../utils/platforms';

const tele2Questions = [
  {
    question:
      'Hur troligt är det att du efter den här kontakten med kundservice skulle rekommendera Tele2 till vänner, bekanta och kollegor?',
    description: '0 är mycket osannolikt och 10 är mycket sannolikt.',
    alternatives: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    isNpsQuestion: true,
  },
  {
    question: 'Tror du att du behöver kontakta oss igen i samma ärende?',
    alternatives: ['Ja', 'Nej'],
    isBranch: true,
  },
  {
    question: 'Vad är främsta orsaken till att du inte vill rekommendera oss?',
    alternatives: [
      'Kundservice - Kötid',
      'Kundservice - Bemötande',
      'Kundservice - Kompetens',
      'Pris',
      'Hårdvara/Utrustning',
      'Instabil tjänst',
      'Otydliga villkor',
      'Övrigt',
    ],
    verticalLayout: true,
  },
  {
    question: 'Hur nöjd är du med kundservicemedarbetarens kunskap och förmåga att hjälpa dig?',
    description: 'Där 1 betyder inte alls nöjd och 5 mycket nöjd.',
    alternatives: ['1', '2', '3', '4', '5'],
  },
  {
    question: 'Hur nöjd är du med kundservicemedarbetarens engagemang och intresse för din fråga?',
    description: 'Där 1 betyder inte alls nöjd och 5 mycket nöjd.',
    alternatives: ['1', '2', '3', '4', '5'],
  },
  {
    question:
      'Vi vill gärna förstå vad vi kan göra för att förbättra oss så lämna gärna dina synpunkter eller förslag nedan.',
    alternatives: 'textarea',
  },
];

const comviqQuestions = [
  {
    question:
      'Hur troligt är det att du efter den här kontakten med kundservice skulle rekommendera Comviq till vänner, bekanta och kollegor?',
    description: '0 är mycket osannolikt och 10 är mycket sannolikt.',
    alternatives: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    isNpsQuestion: true,
  },
  {
    question: 'Tror du att du behöver kontakta oss igen i samma ärende?',
    alternatives: ['Ja', 'Nej'],
    isBranch: true,
  },
  {
    question: 'Vad är främsta orsaken till att du inte vill rekommendera oss?',
    alternatives: [
      'Kundservice - Kötid',
      'Kundservice - Bemötande',
      'Kundservice - Kompetens',
      'Pris',
      'Hårdvara/Utrustning',
      'Instabil tjänst',
      'Otydliga villkor',
      'Övrigt',
    ],
    verticalLayout: true,
  },
  {
    question: 'Hur nöjd är du med kundservicemedarbetarens kunskap och förmåga att hjälpa dig?',
    description: 'Där 1 betyder inte alls nöjd och 5 mycket nöjd.',
    alternatives: ['1', '2', '3', '4', '5'],
  },
  {
    question: 'Hur nöjd är du med kundservicemedarbetarens engagemang och intresse för din fråga?',
    description: 'Där 1 betyder inte alls nöjd och 5 mycket nöjd.',
    alternatives: ['1', '2', '3', '4', '5'],
  },
  {
    question:
      'Vi vill gärna förstå vad vi kan göra för att förbättra oss så lämna gärna dina synpunkter eller förslag nedan.',
    alternatives: 'textarea',
  },
];

export const questionsConfig = {
  [brands.TELE2]: tele2Questions,
  [brands.COMHEMPLAY]: tele2Questions,
  [brands.COMVIQ]: comviqQuestions,
};
