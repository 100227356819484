/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { getBrand } from '../utils/platforms';

import style from './button.module.scss';

export const PrimaryButton = ({ className, ...props }) => {
  const brand = getBrand();

  return <button className={`${style[`primaryButton-${brand}`]} ${className}`} {...props} />;
};

export const GhostButton = ({ className, ...props }) => {
  const brand = getBrand();

  return <button className={`${style[`ghostButton-${brand}`]} ${className}`} {...props} />;
};
