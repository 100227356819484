import React, { useState, useEffect } from 'react';
import { registerListener } from '../clientSocket';

import style from './style.module.scss';

export const TypingIndicator = ({ scrollToBottom }) => {
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    const handleStartTyping = () => {
      setTyping(true);
      scrollToBottom();
    };

    return registerListener('guest.webchat.typing.start', handleStartTyping);
  }, [scrollToBottom, setTyping]);

  useEffect(() => {
    const handleEndTyping = () => {
      setTyping(false);
    };

    return registerListener('guest.webchat.typing.end', handleEndTyping);
  }, [setTyping]);

  useEffect(() => {
    const handleEndTyping = () => {
      setTyping(false);
    };

    return registerListener('guest.webchat.message', handleEndTyping);
  }, [setTyping]);

  if (!typing) {
    return null;
  }

  return (
    <div className={style.message}>
      <div className={style['message-container']}>
        <div className={style.group}>
          <div className={style.bubble}>
            <div className={style.typingGroup} data-testid="Message_typing">
              <div className={style.typingBubble} />
              <div className={style.typingBubble} />
              <div className={style.typingBubble} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
