import React, { memo, useEffect, useCallback, useRef } from 'react';

import MessageGroups from './MessageGroups';
import QueueNumber from './QueueNumber';
import { TypingIndicator } from './TypingIndicator';

import style from './style.module.scss';

const MessageList = memo(({ messages = [], userId, platformId, className, disabled }) => {
  const messagesDiv = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesDiv.current.scrollTop = messagesDiv.current.scrollHeight;
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, messages.length]);

  return (
    <div className={`${style.messages} ${className}`} ref={messagesDiv}>
      <MessageGroups messages={messages} platformId={platformId} disabled={disabled} />
      <QueueNumber scrollToBottom={scrollToBottom} userId={userId} />
      <TypingIndicator scrollToBottom={scrollToBottom} />
    </div>
  );
});

export default MessageList;
