/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getBrand, isComviqBrand } from '../../utils/platforms';

import { ReactComponent as ExternalIconComviq } from '../../assets/svgs/comviq/external.svg';
import { ReactComponent as ExternalIconComhemplay } from '../../assets/svgs/comhemplay/external.svg';

export default (props) => {
  const brand = getBrand();

  if (isComviqBrand(brand)) {
    return <ExternalIconComviq {...props} />;
  }

  return <ExternalIconComhemplay {...props} />;
};
