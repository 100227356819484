import React from 'react';
import ReactMarkdown from 'react-markdown/react-markdown.min';
import { linkifyToMarkdownString, markdownComponents } from './linkifyMarkdown';
import { getClassNamesForMessage, getTestId } from './messageUtils';
import { QuickReplyWrapper } from './QuickReplyWrapper';

const Markdown = ({ message }) => {
  if (!message.text) {
    return null;
  }

  return (
    <div className={getClassNamesForMessage(message)} data-testid={getTestId('Message_from', message)}>
      <ReactMarkdown components={markdownComponents}>{linkifyToMarkdownString(message.text)}</ReactMarkdown>
    </div>
  );
};

const MessageTypeMarkdown = ({ message, disabled }) => {
  const hasQuickReplies = message.quickReplies && message.quickReplies.length > 0;

  return hasQuickReplies ? (
    <QuickReplyWrapper disabled={disabled} message={message}>
      <Markdown message={message} />
    </QuickReplyWrapper>
  ) : (
    <Markdown message={message} />
  );
};

export default MessageTypeMarkdown;
