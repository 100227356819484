import { useEffect } from 'react';
import { getBrand } from '../utils/platforms';

const defaultTheme = {
  '--color-primary': '#18344b',

  '--color-header': '#18344b',
  '--color-header-fade': '#151617',
  '--color-header-text': '#FFFFFF',

  '--color-border': '#EEEEEE',
  '--color-border-focus': '#18344b',

  '--color-hover': '#1680d6',

  '--color-not-selected': '#FFFFFF',
  '--color-selected': '#1789E6',

  '--color-primary-button': '#1789E6',

  '--color-background': '#FFFFFF',
  '--color-input-form-background': '#FFFFFF',

  '--color-text': '#231F20',
  '--color-text-grey': '#707070',
  '--color-link-text': '#EEEEEE',

  '--color-agent-bubble': '#F5F6F7',
  '--color-agent-text': '#48535C',

  '--color-user-bubble': '#C3E4FF',
  '--color-user-text': '#18344B',

  '--color-info-text': '#5C6770',

  '--color-maintenance-background': '#fff8db',
  '--color-maintenance-text': '#b58105',
  '--color-maintenance-border': '#fcefd4',

  '--color-white': '#FFFFFF',

  '--color-agent-active': '#29AB5D',
  '--color-agent-inactive': '#FA7566',

  '--color-gray-light': '#F0F0F0',
  '--color-gray-lighter': '#FAFAFA',
  '--color-gray': '#AAAAAAA',

  '--color-black': '#000',

  '--color-feedback-comment-background': '#F9F9F9',

  '--color-scroll-thumb': '#18344b',
  '--color-scroll-track': 'transparent',

  '--avatar-box-shadow': '0px 0px 2px 2px rgba(0, 0, 0, 0.2)',
  '--avatar-size': '48px',
};

const themes = {
  comviq: {
    '--font-family': "'Roboto', sans-serif",

    '--font-family-pill': "'ComviqSans', 'Roboto', sans-serif",
    '--font-weight-pill': '700',

    '--color-primary': '#e2007a',

    '--color-header': '#FFFFFF',
    '--color-header-fade': '#FFFFFF',
    '--color-header-text': '#222222',

    '--color-border': '#EEEEEE',
    '--color-border-focus': '#e2007a',

    '--color-primary-button': '#ffed00',
    '--color-not-selected': '#222222',
    '--color-selected': '#e2007a',
    '--color-hover': '#ffed00',

    '--color-background': '#f9f9f9',
    '--color-input-form-background': '#FFFFFF',

    '--color-agent-bubble': '#FFFFFF',
    '--color-agent-text': '#222222',

    '--color-user-bubble': '#222222',
    '--color-user-text': '#FFFFFF',

    '--bubble-box-shadow':
      '0px 2px 4px rgba(0, 0, 0, 0.03), 0px 3px 4px rgba(0, 0, 0, 0.03), 0px 1px 5px rgba(0, 0, 0, 0.05)',

    '--color-link-text': '#e2007a',

    '--color-black': '#222222',
    '--color-white': '#ffffff',

    '--color-feedback-comment-background': '#FFFFFF',

    '--color-scroll-thumb': '#222222',

    '--avatar-box-shadow': 'unset',
    '--avatar-size': '24px',
  },
  comhemplay: {
    '--font-family': "'Lineto Brown', sans-serif",

    '--font-family-pill': "'Lineto Brown', sans-serif",
    '--font-weight-pill': 'normal',

    '--color-primary': '#1F272D',

    '--color-header': '#1F272D',
    '--color-header-fade': '#1F272D',

    '--color-primary-button': '#0065FF',
    '--color-button-outline': '#F8FAFB',

    '--color-background': '#171D21',
    '--color-input-form-background': '#171D21',

    '--color-border': '#1F272D',
    '--color-border-focus': '#EEEEEE',

    '--color-text': '#F8FAFB',

    '--color-agent-bubble': '#1F272D',
    '--color-agent-text': '#F8FAFB',

    '--color-user-bubble': '#0065FF',
    '--color-user-text': '#F8FAFB',

    '--color-not-selected': '#F8FAFB',
    '--color-selected': '#0065FF',

    '--color-feedback-comment-background': '#171D21',

    '--color-scroll-thumb': '#F8FAFB',
    '--avatar-size': '48px',
  },
  tele2: {
    '--font-family': "'Lineto Brown', sans-serif",

    '--font-family-pill': "'Lineto Brown', sans-serif",
    '--font-weight-pill': 'normal',

    '--color-primary': '#1F272D',

    '--color-header': '#1F272D',
    '--color-header-fade': '#1F272D',

    '--color-primary-button': '#0065FF',
    '--color-button-outline': '#F8FAFB',

    '--color-background': '#171D21',
    '--color-input-form-background': '#171D21',

    '--color-border': '#1F272D',
    '--color-border-focus': '#EEEEEE',

    '--color-text': '#F8FAFB',
    '--color-info-text': '#949596',
    '--color-link-text': '#EEEEEE',

    '--color-agent-bubble': '#1F272D',
    '--color-agent-text': '#F8FAFB',

    '--color-user-bubble': '#0065FF',
    '--color-user-text': '#F8FAFB',

    '--color-not-selected': '#F8FAFB',
    '--color-selected': '#0065FF',

    '--color-feedback-comment-background': '#171D21',

    '--color-scroll-thumb': '#F8FAFB',
    '--avatar-size': '48px',
  },
};

export default () => {
  useEffect(() => {
    const brand = getBrand();

    const theme = {
      ...defaultTheme,
      ...themes[brand],
    };

    Object.entries(theme).map(([name, value]) => document.documentElement.style.setProperty(name, value));
  }, []);
};
