import { useEffect } from 'react';
import { triggerReadNotification } from './triggerReadNotification';

export const useReadNotification = (userId, platformId) => {
  useEffect(() => {
    const handleFocus = () => {
      triggerReadNotification(userId, platformId);
    };
    window.addEventListener('focus', handleFocus, false);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [platformId, userId]);
};
