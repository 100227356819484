import { useEffect } from 'react';

import { logSync } from '../utils/LoggerClient';

export default (userId, platformId) => {
  useEffect(() => {
    const logHandler = () => {
      logSync(`beforeunload triggered by user. userId: ${userId}`);

      const client = new XMLHttpRequest();

      client.open('POST', `/api/messages/${userId}/client_unload`, false);
      client.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

      client.send(JSON.stringify({ platformId }));
    };

    window.addEventListener('beforeunload', logHandler);

    return () => window.removeEventListener('beforeunload', logHandler);
  }, [userId, platformId]);
};
