/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Image } from './Image';
import { getTestId } from './messageUtils';

import style from './style.module.scss';

const MessageTypeMedia = ({ message }) => {
  const json = JSON.parse(message.imageUrl);

  if (!json) {
    return null;
  }

  const mapped = json.map((m, index) => {
    return (
      <div className={style.mediaItem} key={index}>
        <Image
          src={m.src}
          href={m.href}
          title={m.title}
          price={m.price}
          altText={m.altText}
          testId={getTestId('Message_imageFrom', message)}
          key={index}
        />
      </div>
    );
  });

  return <div className={style.messageType_media}>{mapped}</div>;
};

export default MessageTypeMedia;
