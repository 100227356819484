/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getBrand, isComviqBrand } from '../../utils/platforms';

import { ReactComponent as MinimizeIconComviq } from '../../assets/svgs/comviq/minimize.svg';
import { ReactComponent as MinimizeIconComhemplay } from '../../assets/svgs/comhemplay/minimize.svg';

export default (props) => {
  const brand = getBrand();

  if (isComviqBrand(brand)) {
    return <MinimizeIconComviq {...props} />;
  }

  return <MinimizeIconComhemplay {...props} />;
};
