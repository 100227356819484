import React from 'react';
import classnames from 'classnames';
import { ExpandIcon, ExternalIcon } from '../icons';
import { logOnLinkClick } from './messageUtils';

import style from './style.module.scss';

export const Image = ({ src, href, title, price, altText, testId }) => {
  const isLinkToItself = src === href;

  const classes = classnames(style.imageLink, {
    [style.isLinkToItself]: isLinkToItself,
  });

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => logOnLinkClick({ href, type: 'image-link' })}
      className={classes}
    >
      <img src={src} className={style.image} alt={altText} data-testid={testId} />
      {!isLinkToItself && href && <ExternalIcon aria-label="Öppna" className={style.externalLinkIcon} />}
      {isLinkToItself && href && <ExpandIcon aria-label="Förstora bild" className={style.externalLinkIcon} />}

      {title && (
        <p className={style.title} style={{ margin: 0, padding: 0, textDecoration: 'none' }}>
          {title}
        </p>
      )}
      {price && (
        <small className={style.price} style={{ color: '#aaa', textDecoration: 'none' }}>
          {price}
        </small>
      )}
    </a>
  );
};
