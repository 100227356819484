import React from 'react';
import { SendIcon } from '../icons';

import style from './send.module.scss';

export default ({ send, disabled }) => (
  <button type="submit" className={style.sendBtn} onClick={send} disabled={disabled} data-testid="Send_message_button">
    <SendIcon />
  </button>
);
