/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React from 'react';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import { Image } from './Image';
import { linkComponent } from './messageUtils';

const linkify = new LinkifyIt();

const markdownLinkRegexp = /(.*?)\]\((.*?)\)/;
const linkTextRegexp = /\[(.*?)\]/;
const linkUrlRegexp = /\((.*?)\)/;

linkify.tlds(tlds).add('[', {
  validate: (text, pos) => {
    const tail = text.slice(pos);
    const match = tail.match(markdownLinkRegexp);

    if (match) {
      return match[0].length;
    }

    return 0;
  },
  normalize: (match) => {
    match.text = match.raw.match(linkTextRegexp)?.[1];
    match.url = match.raw.match(linkUrlRegexp)?.[1];
  },
});

export const markdownComponents = {
  a: (a) => linkComponent(a.href, a.children),
  img: (img) => {
    return <Image src={img.src} href={img.src} altText={img.alt} />;
  },
};

export const linkifyToMarkdownString = (message) => {
  if (message === '') {
    return message;
  }

  const matches = linkify.match(message);
  if (!matches) {
    return message;
  }

  const elements = [];
  let lastIndex = 0;
  matches.forEach((match) => {
    // Push preceding text if there is any
    if (match.index > lastIndex) {
      elements.push(message.substring(lastIndex, match.index));
    }

    elements.push(`[${match.text}](${match.url})`);

    lastIndex = match.lastIndex;
  });

  // Push remaining text if there is any
  if (message.length > lastIndex) {
    elements.push(message.substring(lastIndex));
  }

  return elements.length === 1 ? elements[0] : elements.join('');
};
