import React, { useState, useEffect, useCallback } from 'react';

import Side from './Side';
import CloseModal from './header/CloseModal';
import { triggerAudioNotification } from './triggerAudioNotification';
import { triggerReadNotification } from './triggerReadNotification';
import { useReadNotification } from './useReadNotification';
import { triggerConversationStarted } from './triggerConversationStarted';
import { registerListener } from './clientSocket';
import { getConversation } from '../utils/conversation';
import { sendCloseToParentWindow } from '../utils/postMessageToParent';
import { triggerUserConnected } from './triggerUserConnected';

const Chat = ({ userId, platformId, clearSession, resetSession }) => {
  const [conversation, setConversation] = useState({ messages: [] });
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [maintenance, setMaintenance] = useState(null);

  useReadNotification(userId, platformId);

  const handleMessageSent = useCallback(
    (reset) => {
      if (reset) {
        resetSession();
      }

      triggerConversationStarted();
    },
    [resetSession],
  );

  const loadConversation = useCallback(async () => {
    const conv = await getConversation(userId);

    if (conv) {
      setConversation(conv);
    }
  }, [userId]);

  const handleNewMessage = useCallback(async () => {
    await loadConversation(userId);
    triggerReadNotification(userId, platformId);
    triggerAudioNotification();
  }, [userId, platformId, loadConversation]);

  const shouldCloseWithoutConfirmation = () => {
    if (conversation.messages.some((m) => !m.agent)) {
      return false;
    }

    if (
      conversation.messages.some(
        (m) => m.agent.agentId !== 'bot' && m.agent.agentId !== 'Compis' && m.agent.agentId !== 'system',
      )
    ) {
      return false;
    }

    return true;
  };

  const handleClose = () => {
    if (shouldCloseWithoutConfirmation()) {
      clearSession();
      sendCloseToParentWindow();
      return;
    }

    setShowCloseModal(true);
  };

  useEffect(() => {
    const handleStartMaintenanceMessage = ({ message }) => {
      setMaintenance({ message });
    };

    return registerListener('guest.webchat.maintenance.start', handleStartMaintenanceMessage);
  }, []);

  useEffect(() => {
    const handleEndMaintenanceMessage = () => {
      setMaintenance(null);
    };

    return registerListener('guest.webchat.maintenance.end', handleEndMaintenanceMessage);
  }, []);

  useEffect(() => registerListener('guest.webchat.message', handleNewMessage), [handleNewMessage]);
  useEffect(() => registerListener('guest.webchat.update', loadConversation), [loadConversation]);
  useEffect(() => {
    triggerUserConnected(userId, platformId);
    loadConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadConversation]);

  return (
    <>
      <Side
        userId={userId}
        platformId={platformId}
        conversation={conversation}
        maintenance={maintenance}
        onMessageSent={handleMessageSent}
        onCloseClick={handleClose}
      />
      <CloseModal
        userId={userId}
        platformId={platformId}
        showCloseModal={showCloseModal}
        cancelCloseModal={() => setShowCloseModal(false)}
        clearSession={clearSession}
        hasConversationMessages={!!conversation?.messages?.length}
      />
    </>
  );
};

export default Chat;
