import React from 'react';

import { ReactComponent as CloseIconComhemplay } from '../../assets/svgs/comhemplay/close.svg';

import style from './imagepreview.module.scss';

export default ({ imageURL, onCancelClicked }) => (
  <div className={style.imagepreview}>
    <img data-testid="ImagePreview_image" alt="" src={imageURL} />
    <button type="button" title="Ta bort bild" onClick={onCancelClicked}>
      <CloseIconComhemplay />
    </button>
  </div>
);
