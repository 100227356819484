import React from 'react';
import classnames from 'classnames';
import { Message } from './Message';
import { Avatar } from '../Avatar';
import { isComviqPlatform } from '../../utils/platforms';

import style from './style.module.scss';

const formatTime = (datetime) => {
  const date = new Date(datetime);
  return date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' });
};

const MessageGroup = ({ messages = [], platformId, timestamp, speaker, disabled }) => {
  if (!messages.length) {
    return null;
  }

  const classNames = classnames(style.message, {
    [style.user]: speaker.type === 'user',
  });

  const avatarContainerClassNames = classnames(style.avatarContainer, {
    [style.avatarPadding]: speaker.type === 'agent',
  });

  return (
    <div className={classNames}>
      {!isComviqPlatform(platformId) && ['bot', 'agent'].includes(speaker.type) && (
        <div className={avatarContainerClassNames}>
          <Avatar agent={speaker} />
        </div>
      )}

      <div className={style['message-container']}>
        <div className={style.group}>
          {messages.map((message) => (
            <Message key={`msg-${message.id}`} message={message} disabled={disabled} />
          ))}
        </div>

        {speaker.type === 'agent' && (
          <div className={style['info-line']} data-testid="MessageGroup_agentName">
            {`${speaker.name} - ${formatTime(timestamp)}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageGroup;
