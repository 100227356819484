import { v4 as uuid } from 'uuid';
import { logger } from './LoggerClient';
import { getPlatformId } from './platforms';

const TTL = 1000 * 60 * 60 * 24; // 24h
const getKey = (platform) => `SERMO_USER_ID_${platform}`;

export const clearUserId = (platformId) => {
  const platform = platformId || getPlatformId();

  window.localStorage.removeItem(getKey(platform));
};

const getStorageItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key) || '{}');
  } catch (error) {
    return {};
  }
};

export const getUserId = (platformId) => {
  const platform = platformId || getPlatformId();
  const key = getKey(platform);

  const storageItem = getStorageItem(key);

  const userId = storageItem.expiry > new Date().getTime() ? storageItem.userId : uuid();
  const item = JSON.stringify({
    userId,
    expiry: new Date().getTime() + TTL,
  });

  try {
    localStorage.setItem(key, item);
  } catch (error) {
    logger.error(error);
    return null;
  }

  return userId;
};
