/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getBrand, isComviqBrand } from '../../utils/platforms';

import { ReactComponent as ExpandIconComviq } from '../../assets/svgs/comviq/expand.svg';
import { ReactComponent as ExpandIconComhemplay } from '../../assets/svgs/comhemplay/expand.svg';

export default (props) => {
  const brand = getBrand();

  if (isComviqBrand(brand)) {
    return <ExpandIconComviq {...props} />;
  }

  return <ExpandIconComhemplay {...props} />;
};
