import React from 'react';
import Linkify from './Linkify';
import { Image } from './Image';
import { getClassNamesForMessage, getTestId } from './messageUtils';
import { getImageInfo } from './MessageTypeImage';
import { QuickReplyWrapper } from './QuickReplyWrapper';

const Text = ({ message, imageInfo }) => {
  if (!message.text && !imageInfo) {
    return null;
  }

  return (
    <div className={getClassNamesForMessage(message)}>
      <p data-testid={getTestId('Message_from', message)}>
        <Linkify>{message.text}</Linkify>
      </p>
      {imageInfo && (
        <Image
          src={imageInfo.src}
          href={imageInfo.src}
          altText={imageInfo.alt}
          testId={getTestId('Message_imageFrom', message)}
        />
      )}
    </div>
  );
};

const MessageTypeText = ({ message, disabled }) => {
  const imageInfo = getImageInfo(message);

  const hasQuickReplies = message.quickReplies && message.quickReplies.length > 0;

  return hasQuickReplies ? (
    <QuickReplyWrapper disabled={disabled} message={message}>
      <Text message={message} imageInfo={imageInfo} />
    </QuickReplyWrapper>
  ) : (
    <Text message={message} imageInfo={imageInfo} />
  );
};

export default MessageTypeText;
