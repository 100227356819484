import axios from 'axios';

import getQueryObject from './getQueryObject';

export const brands = {
  COMHEMPLAY: 'comhemplay',
  TELE2: 'tele2',
  COMVIQ: 'comviq',
};

export const isBoxerPlatform = (platformId) => typeof platformId === 'string' && platformId.startsWith('boxer');

export const isComhemplayPlatform = (platformId) =>
  typeof platformId === 'string' && platformId.startsWith('comhemplay');

export const isTele2Platform = (platformId) => typeof platformId === 'string' && platformId.startsWith('tele2');

export const isComviqPlatform = (platformId) => typeof platformId === 'string' && platformId.startsWith('comviq');

export const isComhemplayBrand = (brand) => brand === brands.COMHEMPLAY;

export const isTele2Brand = (brand) => brand === brands.TELE2;

export const isComviqBrand = (brand) => brand === brands.COMVIQ;

export const getPlatformId = () => {
  const { platformId } = getQueryObject();

  return platformId || 'webchat';
};

export const getBrand = () => {
  const platformId = getPlatformId();

  if (isComhemplayPlatform(platformId)) {
    return brands.COMHEMPLAY;
  }

  if (isComviqPlatform(platformId)) {
    return brands.COMVIQ;
  }

  return brands.TELE2;
};

export const getBrandForBankId = () => {
  let brand = getBrand();
  if (brand === brands.COMHEMPLAY) {
    brand = 'tele2_play';
  }
  return brand;
};

export const isPlatformClosed = async (platformId) => {
  try {
    const { data } = await axios.get(`/api/platforms/${platformId}`);

    return data?.closed;
  } catch (error) {
    return false;
  }
};
