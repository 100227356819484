import React from 'react';
import Linkify from './Linkify';
import style from './style.module.scss';

const MessageTypeInfo = ({ message }) => {
  return (
    <div className={style.info}>
      <span data-testid="Message_info">
        <Linkify properties={{ target: '_blank' }}>{message.text}</Linkify>
      </span>
    </div>
  );
};

export default MessageTypeInfo;
