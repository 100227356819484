import React from 'react';
import { PillContainer } from './Pills';

export const QuickReplyWrapper = ({ children, message, disabled }) => {
  return (
    <>
      {children}
      <PillContainer disabled={disabled} message={message} />
    </>
  );
};
