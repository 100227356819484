import React, { useCallback, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { getUserId } from '../../utils/userId';
import style from './style.module.scss';
import { getPlatformId } from '../../utils/platforms';
import { logger } from '../../utils/LoggerClient';
import { ExternalIcon } from '../icons';
import { logOnLinkClick } from './messageUtils';

export const PostbackButton = ({ variant, target, presentation, text, url, payload, disabled }) => {
  const [sending, setSending] = useState(false);

  const handleClick = useCallback(async () => {
    const handlePostback = async () => {
      const userId = getUserId();
      const platformId = getPlatformId();

      await axios.post(`/api/messages/${userId}/postback`, { text, payload, platformId });
    };

    const handleUrl = async () => {
      const userId = getUserId();
      const platformId = getPlatformId();
      const linkTarget = target || '_blank';

      await axios.post(`/api/messages/${userId}/link_clicked`, { userId, platformId, text: url });

      logOnLinkClick({ href: url, type: 'button-link', label: text, target });
      window.open(url, linkTarget);
    };

    setSending(true);

    try {
      if (url) {
        await handleUrl();
      } else {
        await handlePostback();
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setSending(false);
    }
  }, [text, payload, target, url]);

  const classNames = clsx(
    style.pill,
    variant === 'secondary' && style.secondary,
    variant === 'historical' && style.historical,
    disabled && style.disabled,
    // count >= INLINE_FLEX_FROM_COUNT && style.inline,
    presentation === 'inline' && style.inline,
  );

  return (
    <button
      data-testid="PostbackButton"
      type="button"
      className={classNames}
      disabled={sending || disabled}
      onClick={handleClick}
      aria-label={disabled}
    >
      {text}
      {url && <ExternalIcon aria-label="Öppna" />}
    </button>
  );
};
