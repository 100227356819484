import axios from 'axios';

export const getConversation = async (userId) => {
  try {
    const { data } = await axios.get(`/api/conversations/${userId}`);

    return data;
  } catch (error) {
    return undefined;
  }
};

export const hasConversation = async (userId) => {
  const conversation = await getConversation(userId);

  if (!conversation) {
    return false;
  }

  return true;
};
