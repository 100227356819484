import { sendAgentRequestedToParentWindow } from '../utils/postMessageToParent';

let messageSent = false;

export const triggerAgentRequested = (data) => {
  if (messageSent) {
    return;
  }

  sendAgentRequestedToParentWindow(data);

  messageSent = true;
};
