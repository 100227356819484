import React from 'react';

import style from './maintenance.module.scss';

export default ({ maintenance }) => {
  if (!maintenance) {
    return null;
  }

  return <div className={style.maintenance__container}>{maintenance.message}</div>;
};
