import React from 'react';
import clsx from 'clsx';

import style from './input.module.scss';

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export default ({ state, onChange, text, onSubmit, disabled, conversationHasNoMessages = false }) => {
  const handleKeyPress = (e) => {
    if (disabled || conversationHasNoMessages) {
      // The textarea would lose focus if we would set the `disabled` attribute
      // on it, so to prevent typing in it when it's disabled we prevent the
      // events instead.
      e.preventDefault();
    }

    if (disabled || conversationHasNoMessages || e.shiftKey || isMobile()) {
      return;
    }

    if (e.key === 'Enter') {
      onSubmit(e);
    }
  };

  let placeholderText = 'Skriv ditt meddelande...';
  if (state?.isQuickReply) {
    if (state?.isOptionalQuickReply) {
      placeholderText = 'Välj ett alternativ eller skriv ditt meddelande...';
    } else {
      placeholderText = 'Välj ett av alternativen...';
    }
  }
  if (state?.isBankId) {
    placeholderText = 'Öppna BankID appen...';
  }
  placeholderText = conversationHasNoMessages ? '' : placeholderText;

  return (
    <textarea
      className={clsx(style.input, (disabled || conversationHasNoMessages) && style.disabled)}
      placeholder={placeholderText}
      onChange={onChange}
      value={text}
      onKeyPress={handleKeyPress}
      maxLength={1000}
      rows={3}
      data-testid="Send_textarea"
    />
  );
};
