import { logger } from './LoggerClient';
import { getPlatformId } from './platforms';

const getKey = (platform) => `SERMO_AGENT_ASSIGNED_${platform}`;

export const clearAgentAssigned = (platformId) => {
  const platform = platformId || getPlatformId();

  window.localStorage.removeItem(getKey(platform));
};

const getStorageItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    if (!item) {
      return undefined;
    }
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return {};
  }
};

export const setAgentAssigned = (platformId, value) => {
  const platform = platformId || getPlatformId();
  const key = getKey(platform);

  const item = JSON.stringify({
    agentAssigned: value,
  });

  try {
    localStorage.setItem(key, item);
  } catch (error) {
    logger.error(error);
    return null;
  }

  return value;
};

export const getAgentAssigned = (platformId) => {
  const platform = platformId || getPlatformId();
  const key = getKey(platform);

  const storageItem = getStorageItem(key);
  if (!storageItem) {
    return undefined;
  }

  const { agentAssigned } = storageItem;

  setAgentAssigned(platformId, agentAssigned);

  return agentAssigned;
};
