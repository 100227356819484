import React from 'react';
import { PostbackButton } from './PostbackButton';

export const Pill = ({ count, disabled, type, variant, target, presentation, text, url, payload, setDisable }) => {
  if (type === 'postback') {
    return (
      <PostbackButton
        count={count}
        presentation={presentation}
        disabled={disabled}
        variant={variant}
        target={target}
        text={text}
        url={url}
        payload={payload}
        setDisable={setDisable}
      />
    );
  }

  return null;
};
