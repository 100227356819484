import React, { useState, useEffect } from 'react';

import { clearUserId } from '../../utils/userId';
import { clearAgentRequested } from '../../utils/agentRequested';
import { isBoxerPlatform, isPlatformClosed, isTele2Platform } from '../../utils/platforms';

import style from './chatended.module.scss';

export const PlatformClosed = ({ platformId }) => {
  if (isBoxerPlatform(platformId)) {
    return (
      <div className={style.chatClosedMessage} data-testid="ChatEnded_platform_closed">
        <p data-testid="ChatEnded_message">
          <span>Chatten är stängd. Du kan kontakta Kundservice via </span>
          <a href="mailto:Kundservice-info@comhem.com">e-post</a>
          <span> eller </span>
          <a href="tel:90 222">telefon</a>
          <span> istället.</span>
        </p>
      </div>
    );
  }

  if (isTele2Platform(platformId)) {
    return (
      <div className={style.chatClosedMessage} data-testid="ChatEnded_platform_closed">
        <p data-testid="ChatEnded_message">
          <span>Chatten är stängd. Du kan kontakta Kundservice via vårt </span>
          <a data-testid="ChatEnded_contactLink" href="https://www.tele2.se/kundservice/kontakt" target="_top">
            kontaktformulär
          </a>
          <span> eller </span>
          <a href="tel:90 222">telefon</a>
          <span> istället.</span>
        </p>
      </div>
    );
  }

  return (
    <div className={style.chatClosedMessage} data-testid="ChatEnded_platform_closed">
      <p data-testid="ChatEnded_message">Chatten är stängd, välkommen åter.</p>
    </div>
  );
};

export const ChatEnded = ({ reason, platformId }) => {
  const [platformClosed, setPlatformClosed] = useState(undefined);
  const message = reason || 'Chatten är avslutad';

  useEffect(() => {
    isPlatformClosed(platformId).then(setPlatformClosed);
  }, [platformId]);

  if (platformClosed === undefined) {
    return null;
  }

  if (platformClosed === true) {
    return <PlatformClosed platformId={platformId} />;
  }

  return (
    <div className={style.chatClosedMessage} data-testid="ChatEnded">
      <p data-testid="ChatEnded_message">{message}</p>
      <p>
        <a
          href={window.location.href}
          onClick={() => {
            clearUserId();
            clearAgentRequested();
          }}
          data-testid="ChatEnded_newSessionLink"
        >
          Öppna en ny chatt
        </a>
      </p>
    </div>
  );
};
