import React, { useState, useEffect } from 'react';
import { registerListener } from '../clientSocket';
import { setAgentRequested } from '../../utils/agentRequested';
import { setAgentAssigned } from '../../utils/agentAssigned';

import style from './style.module.scss';
import { triggerAgentAssigned } from '../triggerAgentAssigned';
import { triggerAgentRequested } from '../triggerAgentRequested';

const QueueNumber = ({ userId, scrollToBottom }) => {
  const [queueNumber, setQueueNumber] = useState();

  useEffect(() => {
    const handleQueueNumber = ({ number }) => {
      if (queueNumber && Number(queueNumber) < Number(number)) {
        // Queue number can get higher when other customer gets handover to this customer's queue.
        // Don't show those cases to the client.
        return;
      }

      if (number === null) {
        // when an agent takes over the conversation
        setAgentAssigned(null, true);
        triggerAgentAssigned({ userId, queueNumber: number });
      } else {
        // when user waiting in the queue
        setAgentRequested(null, true);
        triggerAgentRequested({ userId, queueNumber: number });
      }

      setQueueNumber(number);
      scrollToBottom();
    };

    return registerListener('guest.webchat.queue', handleQueueNumber);
  }, [queueNumber, scrollToBottom, userId]);

  if (!queueNumber) {
    return null;
  }

  return (
    <div className={style.queueNumber}>
      <span data-testid="MessageList_queueNumber">{`Din plats i kön: ${queueNumber}`}</span>
    </div>
  );
};

export default QueueNumber;
