import React from 'react';

import avatar1 from '../assets/avatars/chat1.png';
import avatar2 from '../assets/avatars/chat2.png';
import avatar3 from '../assets/avatars/chat3.png';
import avatar4 from '../assets/avatars/chat4.png';

import botterinoAvatar from '../assets/avatars/bot.png';
import compisAvatar from '../assets/avatars/compis.png';
import compysAvatar from '../assets/avatars/compys.png';

import { AvatarIcon } from './icons';

import { BOT_AGENT, COMHEMPLAY_BOT_AGENT, COMVIQ_BOT_AGENT, hasHumanAgentName, TELE2_BOT_AGENT } from '../utils/agent';
import { getBrand, isComhemplayBrand, isComviqBrand, isTele2Brand } from '../utils/platforms';

import style from './avatar.module.scss';

const avatars = {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
};

const getBotName = () => {
  const brand = getBrand();

  if (isComviqBrand(brand)) {
    return COMVIQ_BOT_AGENT.name;
  }

  if (isComhemplayBrand(brand)) {
    return COMHEMPLAY_BOT_AGENT.name;
  }

  if (isTele2Brand(brand)) {
    return TELE2_BOT_AGENT.name;
  }

  return BOT_AGENT.name;
};

const getBotAvatar = () => {
  const brand = getBrand();

  if (isComviqBrand(brand)) {
    return compisAvatar;
  }

  if (isComhemplayBrand(brand)) {
    return compysAvatar;
  }

  if (isTele2Brand(brand)) {
    return botterinoAvatar;
  }

  return botterinoAvatar;
};

const getAvatar = (name, avatar, avatarUrl) => {
  if (avatarUrl) {
    return avatarUrl;
  }

  if (avatar) {
    return avatars[avatar];
  }

  if (!name) {
    return getBotAvatar();
  }

  if (hasHumanAgentName(name)) {
    return undefined;
  }

  return getBotAvatar();
};

const getAgentName = (agent) => {
  if (!agent.name || hasHumanAgentName(agent.name) === false) {
    return getBotName();
  }

  return agent.name;
};

export const Avatar = ({ agent }) => {
  if (!agent || agent.agentId === 'system') {
    return null;
  }

  const avatarSrc = getAvatar(agent.name, agent.avatar, agent.avatarUrl);

  return (
    <div className={style.avatarContainer}>
      {avatarSrc && <img data-testid="Avatar_image" alt="avatar" src={avatarSrc} />}
      {!avatarSrc && <AvatarIcon data-testid="Avatar_image" />}
      {agent.active === true && <div className={`${style.status} ${style.active}`} />}
      {agent.active === false && <div className={`${style.status} ${style.inactive}`} />}
    </div>
  );
};

export const AvatarWithName = ({ agent }) => {
  if (!agent || agent.agentId === 'system') {
    return <div />;
  }

  return (
    <div className={style.avatar}>
      <Avatar agent={agent} />
      <div data-testid="Avatar_name" className={style.name}>
        {getAgentName(agent)}
      </div>
    </div>
  );
};
