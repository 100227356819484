import axios from 'axios';

const log = (message, level) => axios.post(`/api/log/${level}`, { log: message });

export const logger = {
  info: (message) => log(message, 'info'),
  warn: (message) => log(message, 'warn'),
  error: (message) => log(message, 'error'),
};

export const logSync = (message) => {
  const client = new XMLHttpRequest();

  client.open('POST', '/api/log/info', false);
  client.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

  client.send(JSON.stringify({ log: message }));
};
