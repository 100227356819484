/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getBrand, isComviqBrand } from '../../utils/platforms';

import { ReactComponent as CloseIconComviq } from '../../assets/svgs/comviq/close.svg';
import { ReactComponent as CloseIconComhemplay } from '../../assets/svgs/comhemplay/close.svg';

export default (props) => {
  const brand = getBrand();

  if (isComviqBrand(brand)) {
    return <CloseIconComviq {...props} />;
  }

  return <CloseIconComhemplay {...props} />;
};
