import { io } from 'socket.io-client';

/**
 * @type {import('socket.io-client').Socket}
 */
let socket;

export const connectUser = (userId) => {
  if (socket) {
    socket.close();
    socket = undefined;
  }

  if (!userId) {
    throw new Error('Cannot connect user to socket without id');
  }

  socket = io({
    path: '/api/io',
    transports: ['websocket'],
    query: { userId },
  });

  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve();
    });
  });
};

export const deregisterListener = (eventName, fn) => {
  if (socket) {
    socket.off(eventName, fn);
  }
};

export const registerListener = (eventName, fn) => {
  if (socket) {
    socket.on(eventName, fn);
    return () => deregisterListener(eventName, fn);
  }

  return () => {};
};

export const isConnected = () => !!socket;

export const emit = (eventName, event) => {
  socket.emit(eventName, event);
};
