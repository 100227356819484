import debounce from 'debounce';
import axios from 'axios';

const sendUserConnectedEvent = debounce(
  (userId, platformId) => axios.post(`/api/events/inbound/${userId}/connected`, { platformId }),
  2000,
);

export const triggerUserConnected = (userId, platformId) => {
  sendUserConnectedEvent(userId, platformId);
};
