/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React from 'react';
import Linkify from 'react-linkify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

import { linkComponent } from './messageUtils';

const linkify = new LinkifyIt();

const markdownLinkRegexp = /(.*?)\]\((.*?)\)/;
const linkTextRegexp = /\[(.*?)\]/;
const linkUrlRegexp = /\((.*?)\)/;

linkify.tlds(tlds).add('[', {
  validate: (text, pos) => {
    const tail = text.slice(pos);
    const match = tail.match(markdownLinkRegexp);

    if (match) {
      return match[0].length;
    }

    return 0;
  },
  normalize: (match) => {
    match.text = match.raw.match(linkTextRegexp)?.[1];
    match.url = match.raw.match(linkUrlRegexp)?.[1];
  },
});

export default ({ children }) => (
  <Linkify matchDecorator={(text) => linkify.match(text)} componentDecorator={linkComponent}>
    {children}
  </Linkify>
);
