import debounce from 'debounce';
import axios from 'axios';

const sendMessagesReadEvent = debounce(
  (userId, platformId) => axios.post(`/api/messages/${userId}/messages_read`, { platformId }),
  2000,
);

export const triggerReadNotification = (userId, platformId) => {
  if (!document.hasFocus()) {
    return;
  }

  sendMessagesReadEvent(userId, platformId);
};
