import React, { useState, useEffect, useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import axios from 'axios';
import clsx from 'clsx';
import style from './style.module.scss';
import Linkify from './Linkify';
import { getBrandForBankId, getPlatformId } from '../../utils/platforms';
import { getUserId } from '../../utils/userId';
import { linkComponent } from './messageUtils';

const Text = ({ message, link, disabled }) =>
  link ? (
    <div type="button" className={style.pills} style={{ textAlign: 'center' }}>
      {linkComponent(link, message, 'samedevicebutton', clsx(style.pill, disabled && style.disabled))}
    </div>
  ) : (
    <p className={style.textPadding}>
      <Linkify>{message}</Linkify>
    </p>
  );

const Spacer = () => <div className={style.spacer} />;

const getQRCode = (qrCode) => {
  if (!qrCode || qrCode.length === 0) {
    return <div style={{ width: 128, height: 128 }}>&nbsp;</div>;
  }
  return <QRCodeSVG value={qrCode} className={style['bankid-qrcode']} />;
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const getOrderRef = (message) => {
  const values = message.text.split('###');
  return values[0];
};

const getQrStartToken = (message) => {
  const values = message.text.split('###');
  return values[1];
};

const getAutoStartToken = (message) => {
  const values = message.text.split('###');
  return values[2];
};

const getBankIDLink = (message) => {
  const token = getAutoStartToken(message);
  return `bankid:///?autostarttoken=${token}&redirect=null`;
};

// eslint-disable-next-line no-unused-vars
export const BankIdContainer = ({ message, disabled }) => {
  const [complete, setComplete] = useState(false);
  const [polling, setPolling] = useState(false);
  const [qrPolling, setQrPolling] = useState(false);
  const [failed, setFailed] = useState(false);
  const [qrCode, setQrCode] = useState('');

  const qrStartToken = getQrStartToken(message);
  const orderRef = getOrderRef(message);
  const autoStartLink = getBankIDLink(message);
  const platformId = getPlatformId();
  const brand = getBrandForBankId();
  const userId = getUserId(platformId);

  const POLL_INTERVAL = 2000;
  const QR_POLL_INTERVAL = 1000;

  useInterval(
    async () => {
      let status = '';

      if (polling || complete || failed) {
        return;
      }

      setPolling(true);

      try {
        const result = await axios.post(`/api/events/inbound/bankid/poll`, { orderRef, platformId, brand, userId });

        status = result.data.status;

        if (status === 'error') {
          setFailed(true);
        }
      } catch (e) {
        setFailed(true);
      }

      if (status === 'complete') {
        setComplete(true);
      }
      if (status === 'error') {
        setFailed(true);
      }

      setPolling(false);
    },
    disabled || complete ? null : POLL_INTERVAL,
  );

  useInterval(
    async () => {
      if (qrPolling || complete || failed) {
        return;
      }

      setQrPolling(true);

      try {
        const result = await axios.post(`/api/events/inbound/bankid/qrcode`, { orderRef, brand, userId });
        if (result?.data?.qrStartToken) {
          setQrCode(result.data.qrStartToken);
        }
      } catch (e) {
        setQrPolling(false);
      }

      setQrPolling(false);
    },
    disabled || complete ? null : QR_POLL_INTERVAL,
  );

  if (disabled || !orderRef || !qrStartToken) {
    return null;
  }

  const textMessage = `Om du har BankID installerat på en annan enhet\n1. Starta BankID-appen på din mobil/surfplatta.\n2. Tryck på QR-symbolen in BankID-appen.\n3. Rikta kameran mot QR-koden nedan.`;

  // Previous text messages, saved for reference
  // const textMessage = `Om du vill använda BankID på din mobil eller surfplatta starta BankID-appen och tryck på QR-ikonen. Skanna sedan QR-koden nedan`;
  // <Text message="Om du vill använda BankID på denna enhet väljer du alternativet nedan." disabled={disabled} />

  return (
    <div className={style.bubble}>
      <span data-testid="Message_info">
        <Spacer />
        <Text message="Använd BankID på denna enhet" link={autoStartLink} disabled={disabled} />
        <Spacer />
        <Text message={textMessage} disabled={disabled} />
        {getQRCode(qrCode)}
      </span>
    </div>
  );
};
