import React, { useState, useCallback, useEffect } from 'react';

import { startHeartbeat, stopHeartbeat } from './heartbeat';
import useBeforeUnload from './useBeforeUnload';

import Chat from './Chat';
import { NotSupportedView, PlatformClosedView } from './Side';

import { getUserId, clearUserId } from '../utils/userId';
import { connectUser } from './clientSocket';
import { isComviqPlatform, isPlatformClosed } from '../utils/platforms';
import { hasConversation } from '../utils/conversation';
import { initializeConversation } from '../utils/initializeConversation';

import useTheme from './useTheme';

import './style.module.scss';
import { clearAgentRequested, setAgentRequested } from '../utils/agentRequested';
import { clearAgentAssigned, setAgentAssigned } from '../utils/agentAssigned';

const App = ({ userId, platformId }) => {
  useBeforeUnload(userId, platformId);

  const clearSession = useCallback(() => {
    clearUserId(platformId);
    clearAgentRequested(platformId);
    clearAgentAssigned(platformId);
    stopHeartbeat();
  }, [platformId]);

  const resetSession = useCallback(() => {
    clearSession();
    window.location.reload();
  }, [clearSession]);

  return (
    <Chat
      key={userId}
      userId={userId}
      platformId={platformId}
      clearSession={clearSession}
      resetSession={resetSession}
    />
  );
};

export default ({ platformId }) => {
  useTheme();

  const userId = getUserId(platformId);
  const [loading, setLoading] = useState(true);
  const [platformClosed, setPlatformClosed] = useState(false);

  useEffect(() => {
    const start = async () => {
      if (userId) {
        await connectUser(userId);
        startHeartbeat(userId, platformId);
        setLoading(false);
      }
    };

    const loadChat = async () => {
      const isOngoing = await hasConversation(userId);
      if (isOngoing) {
        await start();
        return;
      }

      // Note: needs to be here to not override when reloading page
      setAgentRequested(platformId, false);
      setAgentAssigned(platformId, false);

      const isClosed = await isPlatformClosed(platformId);
      if (isClosed && !isComviqPlatform(platformId)) {
        setPlatformClosed(true);
        setLoading(false);
        return;
      }

      const isInitialized = await initializeConversation(userId, platformId);
      if (isInitialized) {
        await start();
      }
    };

    loadChat();
  }, [platformId, userId]);

  if (!window.WebSocket) {
    return <NotSupportedView platformId={platformId} message="Din webbläsare stöds inte" />;
  }

  if (!userId) {
    return <NotSupportedView platformId={platformId} message="Chatten kräver åtkomst till localStorage" />;
  }

  if (loading) {
    return null;
  }

  if (platformClosed) {
    return <PlatformClosedView platformId={platformId} />;
  }

  return <App userId={userId} platformId={platformId} />;
};
